// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-airconditioning-js": () => import("./../../../src/pages/airconditioning.js" /* webpackChunkName: "component---src-pages-airconditioning-js" */),
  "component---src-pages-boilers-js": () => import("./../../../src/pages/boilers.js" /* webpackChunkName: "component---src-pages-boilers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nieuwbouw-js": () => import("./../../../src/pages/nieuwbouw.js" /* webpackChunkName: "component---src-pages-nieuwbouw-js" */),
  "component---src-pages-onderhoud-js": () => import("./../../../src/pages/onderhoud.js" /* webpackChunkName: "component---src-pages-onderhoud-js" */),
  "component---src-pages-sanitair-js": () => import("./../../../src/pages/sanitair.js" /* webpackChunkName: "component---src-pages-sanitair-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-verwarming-js": () => import("./../../../src/pages/verwarming.js" /* webpackChunkName: "component---src-pages-verwarming-js" */),
  "component---src-pages-voorwaarden-js": () => import("./../../../src/pages/voorwaarden.js" /* webpackChunkName: "component---src-pages-voorwaarden-js" */)
}

